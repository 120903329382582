import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import '../../App.scss';

export default (props) => {

    if (!props.adventure.checkpoints) {
        return null;
    }

    const total = props.checkpoints.reduce((total, checkpoint) => total + checkpoint.pointsCorrect, 0);

    const lost = Object.keys(props.adventure.checkpoints).reduce((total, checkpoint) => {
        return total + props.adventure.checkpoints[checkpoint].pointsLost;
    }, 0);
    
    // Don't try to sum the points from the checkpoints, the API handle that once they're complete
    const won = props.adventure.points ?? '0';

    const available = total - (won + lost);

    return (
        <>
        <Row>
            <Col xs={6}>
                <Card className="puzzletype shadow p-3 mb-5 bg-white rounded adventure-title responsive-subtitle"><h2>Points Available</h2>
                <h2>{available}</h2>
                </Card>
            </Col>
            <Col xs={6}>
                    <Card className="puzzletype shadow p-3 mb-5 bg-white rounded adventure-title responsive-subtitle"><h2>Your Score</h2>
                    <h2>{won}</h2>
                    </Card>
            </Col>
        </Row>

        </>
    )
}
