import { ulid } from "ulid";

const LOCAL_DOMAIN = 'http://localhost:8000/api';
const PRODUCTION_DOMAIN = '//'+window.location.host+'/api';

export default new class API {

    domain = PRODUCTION_DOMAIN;

    initialData = {
        online: window.navigator.onLine,
        adventure: {
            status: 'Pending'
        }
    };

    data = {};

    updateState = null;

    paymentIntent = null;

    syncingAttempts = false;

    constructor() {
        if (window.location.href.indexOf('localhost') !== -1) {
            this.domain = LOCAL_DOMAIN;
        } else {
            this.domain = PRODUCTION_DOMAIN;
        }

        if (localStorage.getItem('data')) {
            this.data = JSON.parse(localStorage.getItem('data'));
        }

        window.addEventListener('online', () => this.handleNetworkChange(true));
        window.addEventListener('offline', () => this.handleNetworkChange(false));
    }

    offline() {
        // return true;
        return window.navigator.onLine === false;
    }

    handleNetworkChange(online) {
        this.mergeData({ online });

        if (online) {
            this.uploadPendingAttempts();
        }
    }

    async fromData(path) {
        return this.data[path];
    }

    nudge() {
        this.mergeData(this.data.adventure ? this.data : this.initialData);

        this.loadTrail();

        if (localStorage.getItem('token')) {
            this.getAdventure(localStorage.getItem('token')).then(() => this.loadCheckpoints());
        }
    }

    reset() {
        this.paymentIntent = null;
        localStorage.clear();
        this.data = this.initialData;
        this.mergeData(this.initialData).then(() => {
            this.nudge();
        });
    }

    setSetUpdater(updateState) {
        this.updateState = updateState;
        // this.data = localStorage.getItem('data') ? JSON.parse(localStorage.getItem('data')) : this.initialData;
    }

    async getPaymentIntent() {
        if (this.paymentIntent) {
            return this.paymentIntent;
        }

        await this.get('/paymentIntent').then(paymentIntent => {
            this.paymentIntent = paymentIntent;
        });

        return this.paymentIntent;
    }

    clearPaymentIntent() {
        this.paymentIntent = null;
    }

    paymentComplete(paymentIntent, paymentSource) {
        return this.post('/paymentIntent', Object.assign({}, paymentIntent, {paymentSource}));
    }

    getAdventure(token) {
        if (this.offline()) {
            return this.fromData('adventure');
        }

        return this.get('/adventures?token=' + token)
            .then(adventure => this.mergeData({ adventure }));
    }

    async enrol(code) {
        const adventure = await this.get('/adventures/enrol?code=' + code);
        
        return this.mergeData({ adventure })
            .then(async result => {
                localStorage.setItem('token', result.adventure.token);
                return result.adventure
            })
            .then(() => this.loadCheckpoints());
    }

    setTeamName(teamName) {
        return this.post('/adventures/teamName', {
            token: this.data.adventure.token,
            teamName: teamName
        }).then(adventure => this.mergeData({ adventure }));
    }

    setCustomPrize(customPrize) {
        return this.post('/adventures/customPrize', {
            token: this.data.adventure.token,
            customPrize: customPrize
        }).then(adventure => this.mergeData({ adventure }));
    }

    attemptInitialQuestion(answer) {
        return this.post('/adventures/initialQuestion', {
            token: this.data.adventure.token,
            attemptId: ulid(),
            answer: answer
        }).then(adventure => this.mergeData({ adventure }));
    }

    attemptFinalQuestion(answer) {
        return this.post('/adventures/finalQuestion', {
            token: this.data.adventure.token,
            attemptId: ulid(),
            answer: answer
        }).then(adventure => this.mergeData({ adventure }));
    }

    start() {
        return this.get('/adventures/start?token=' + this.data.adventure.token)
            .then(adventure => this.mergeData({ adventure }));
    }

    loadTrail() {
        console.log('trail')
        if (this.offline()) {
            return this.fromData('trail');
        }

        this.get('/trails').then(trail => this.mergeData({ trail }));
    }

    loadCheckpoints() {
        if (this.offline()) {
            return this.fromData('checkpoints');
        }

        this.get('/trails/' + this.data.adventure.trailId + '/checkpoints').then(checkpoints => this.mergeData({ checkpoints }));
    }

    async attemptCheckpoint(checkpointId, answer, attemptId) {
        const result = await this.handleAttemptOffline(checkpointId, answer, attemptId);

        if (this.offline() === false) {
            this.uploadPendingAttempts();
        }

        return result;

        /*
        if (this.offline()) {
            return this.handleAttemptOffline(checkpointId, answer, attemptId);
        }

        return this.post('/adventures/checkpoint', {
            token: this.data.adventure.token,
            checkpointId: checkpointId,
            answer: answer,
            attemptId: attemptId
        }).then(adventure => this.mergeData({ adventure }));
        */
    }

    checkExpired() {
        return this.post('/adventures/checkExpired?', {
            token: this.data.adventure.token
        }).then(adventure => this.mergeData({ adventure }));
    }

    playNow(paymentSource) {
        return this.get('/adventures/book?paymentSource=' + paymentSource).then((adventure) => this.enrol(adventure.code));
    }

    getCode(paymentSource) {
        return this.get('/adventures/book?paymentSource='+paymentSource);
    }

    getLeaderBoard() {
        return this.get('/leaderboard');
    }

    async mergeData(newData) {
        this.data = Object.assign({}, this.data, newData);

        this.updateState(this.data);

        localStorage.setItem('data', JSON.stringify(this.data));

        return this.data;
    }

    get(path) {
        return new Promise((resolve, reject) => {
            return fetch(this.domain + path, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => response.json())
                .then(data => {
                if (data.message) {
                    reject(data.message);
                } else {
                    resolve(data);
                }
            });
        });
    }

    post(path, data) {
        return new Promise((resolve, reject) => {
            return fetch(this.domain + path, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    reject(data.message);
                } else {
                    resolve(data);
                }
            });
        });
    }

    /*
     *  Lets pretend to be the API
     */

    async handleAttemptOffline(checkpointId, answer, attemptId) {
        let correct;

        const newData = Object.assign({}, this.data);
        if (typeof newData.pendingAttempts === 'undefined') {
            newData.pendingAttempts = [];
        }

        // Stop now if we've already seen it
        const alreadyStored = newData.pendingAttempts.find(attempt => attempt.attemptId === attemptId);
        console.log(alreadyStored);
        if (alreadyStored) {
            return {
                offline: true
            };
        }

        // Save the answer to sync later
        newData.pendingAttempts.push({
            attemptId: attemptId, 
            attemptedAt: this.getDateTimestamp(),
            order: this.getUnixTimestamp(),
            token: this.data.adventure.token,
            checkpointId: checkpointId,
            answer: answer
        });

        // Check if the user answer correctly
        const checkpoint = this.data.checkpoints.find(check => check.id === checkpointId);
        const attempt = Object.assign({}, this.data.adventure.checkpoints[checkpointId]);

        if (this.answerMatches(answer, checkpoint.answer)) {
            attempt.attempts++;
            attempt.pointsWon = checkpoint.pointsCorrect;

            correct = true;
        } else {
            attempt.attempts++;
            attempt.pointsLost = checkpoint.pointsWrong;

            correct = false;
        }

        if (correct) {
            newData.adventure.points = newData.adventure.points + (attempt.pointsWon - attempt.pointsLost);
        }
        
        if (correct || attempt.attempts >= checkpoint.maxAttempts) {
            attempt.status = correct ? 'Completed' : 'Failed';
        }

        newData.adventure.checkpoints[checkpointId] = attempt;

        // Check if there's still more answers to attempt
        const pending = Object.keys(newData.adventure.checkpoints).reduce((combined, checkpointId) => {
            return combined || newData.adventure.checkpoints[checkpointId].status === 'Pending' || newData.adventure.checkpoints[checkpointId].status === 'Attempted';
        }, false);

        if (pending === false) {
            newData.adventure.status = 'Finished';
        }

        this.mergeData(newData);

        return {
            offline: true
        };
    }

    uploadPendingAttempts() {
        if (this.syncingAttempts) {
            console.warn('Not syncing attempts - already syncing');
            return;
        }

        if (!this.data.pendingAttempts) {
            return;
        }

        this.syncingAttempts = true;

        return this.post('/adventures/checkpoints', {
            token: this.data.adventure.token,
            attempts: this.data.pendingAttempts
        }).then(adventure => {
            this.mergeData({ pendingAttempts: [], adventure });
            this.syncingAttempts = false;
        }).catch(() => {
            this.syncingAttempts = false;
        });
    }

    answerMatches(attempt, actual) {
        const attempts = attempt.toLowerCase().split(',').map(a => a.trim());
        const actuals = actual.toLowerCase().split(',').map(a => a.trim());

        return actuals.filter(v => attempts.includes(v)).length > 0;
    }

    getUnixTimestamp() {
        return Math.round(new Date().getTime() / 1000);
    }

    getDateTimestamp() {
        const date = new Date();
        return date.getUTCFullYear() + '-' + this.leadingZero((date.getUTCMonth() + 1)) + '-' + this.leadingZero(date.getUTCDate()) +
            ' ' +
            this.leadingZero(date.getUTCHours()) + ':' + this.leadingZero(date.getUTCMinutes()) + ':' + this.leadingZero(date.getUTCSeconds())
    }

    leadingZero(input) {
        const str = '' + input;
        if (str.length === 1) {
            return '0' + str;
        }
        return str;
    }
}()
