import React from 'react';
import { useEffect } from 'react';
import API from '../../Services/API';

export default () => {

    useEffect(() => {
        API.uploadPendingAttempts();
    }, []);

    return (
        <>
            <h1>Mission Complete!</h1>
            
            {API.offline() ?
                <p>Go online to see your summary and final score!</p> :
                <p>Just a moment while we check your score...</p>
            }
        </>
    )
}
