import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Snippets from '../../Services/Snippets';

export default () => {
    return (
        <Row>
            <Col className="mt-4 pt-3 border-top">
                <p className="tiny">Adventure Trails are brought to you by Boundless Workshop Ltd. registered in Scotland by company number SC474064</p>
            </Col>
            <Col className="mt-4 pt-3 border-top">
                <p className="tiny">Useful links
                    <li><a className="link" href={Snippets.get('Help_url')}>Help</a></li>
                    <li><a className="link" href="https://boundlessworkshop.co.uk">T's & C's</a></li>
                    <li><a className="link" href="https://boundlessworkshop.co.uk">Contact us</a></li>
                </p>
            </Col>
        </Row>
    );
}
