import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import API from '../../Services/API';
import Loading from '../../Components/Loading';
import { ulid } from 'ulid'

export default (props) => {
    const checkpointId = props.checkpointId;

    const [answer, setAnswer] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const innerRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (!innerRef || !innerRef.current) {
                return;
            }

            innerRef.current.focus();
        }, 500);
    });

    const submitAnswer = (event) => {
        event.preventDefault();

        if (submitting || answer === '') {
            return;
        }

        setSubmitting(true);
        API.attemptCheckpoint(checkpointId, answer, ulid()).then(() => {
            setError('Sorry, that answer was incorrect');
            setAnswer('');
            setSubmitting(false);

            setTimeout(() => setError(null), 3000);
        }).catch(err => {
            setError(err);
            setSubmitting(false);
        });
    }

    return (
        <Form onSubmit={(e) => submitAnswer(e)}>
            <Form.Group>
                <Form.Control type="text"
                    placeholder="Type your answer"
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                    disabled={submitting}
                    autoFocus={true}
                    ref={innerRef}
                />
            </Form.Group>

            <p className="text-danger" onClick={() => setError('')}>
                {error ? <span>&times; {error}</span> : ''}
            </p>

            <Button variant="primary" type="button" onClick={(e) => submitAnswer(e)} disabled={submitting}>
                {submitting ? <>Submit <Loading inline={true} variant="light" size="sm" /></> : 'Submit'}
            </Button>
        </Form>
    );
}
