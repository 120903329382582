import React, {useRef} from 'react';
import EnterCode from './EnterCode';
import Payment from '../Payment';
import logo from '../../images/logo.png';
import Row from 'react-bootstrap/Row';
import PlayNow from './PlayNow';

export default ({trail}) => {

    const codeRef = useRef();

    return (
        <>

            <Row>
                <img className="logo" src={logo} alt="logo"></img>
            </Row>

            {trail.code && <EnterCode ref={codeRef} debug={trail.debug} />}

            {trail.playnow && <PlayNow />}

            {trail.payments && <Payment onComplete={(c) => codeRef.current.setCode(c)} trail={trail} />}
        </>
    );
}

