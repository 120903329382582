import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import API from '../../Services/API';
import Loading from '../../Components/Loading';
import Snippets from '../../Services/Snippets';

export default forwardRef((props, ref) => {

    const [code, setCode] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useImperativeHandle(ref, () => ({

        setCode(newCode) {
            setCode(newCode);
        }

    }));

    const submit = (event) => {
        event.preventDefault();
        setSubmitting(true);

        API.enrol(code).catch(err => {
            alert(err);
            setSubmitting(false);
        });
    }

    return (
        <>

            <Row className="mb-4">
                <Col>

                <h1 className="adventure-title">{Snippets.get('Trail_name')}</h1>

                <p className="content">{Snippets.get('Headline_info')}</p>

                    <h2>Get started</h2>
                    <p>{Snippets.get('Get_started')}</p>

                    <Form onSubmit={(e) => submit(e)}>
                        <Form.Group>
                            {/* <Form.Label>Enter your code</Form.Label> */}
                            <Form.Control type="text" placeholder="Enter your 6 digit code" value={code} onChange={e => setCode(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary"
                            type="button"
                            onClick={(ev) => submit(ev)}
                            disabled={submitting}>
                            {submitting ? <>Submit <Loading inline={true} variant="light" size="sm" /></> : 'Submit'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
});
