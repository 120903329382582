import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import API from '../../Services/API';
import Loading from '../../Components/Loading';
import Form from 'react-bootstrap/Form';
import Snippets from '../../Services/Snippets';

export default () => {

    const [wrong, setWrong] = useState(false);
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState('');

    const submit = (ev) => {
        ev.preventDefault();

        setLoading(true);
        setWrong(false);

        API.attemptFinalQuestion(answer)
            .then((adventure) => {
                setWrong(true);
                setLoading(false);
                setAnswer('');
            }).catch((err) => {
                setLoading(false);
                alert(err)
            });
    }

    return (
        <>

            <Row>
                <Col>

                    <p className="content">{Snippets.get('finalQuestion')}</p>

                    {wrong && <p className="text-danger">{Snippets.get('finalQuestionIncorrect')}</p>}

                    <Form onSubmit={(ev) => submit(ev)}>
                        <Form.Group>
                            <Form.Control type="text" placeholder="Type here" value={answer} onChange={e => setAnswer(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" className="col-6 offset-3" type="button" onClick={(ev) => submit(ev)} disabled={loading}>
                            {loading ? <>Submit <Loading inline={true} size="sm" variant="light" /></> : 'Submit'}
                        </Button>
                    </Form>

                </Col>
            </Row>
        </>
    );
}

