import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import API from '../Services/API';

export default (props) => {
    const [open, setOpen] = useState(true);

    return (
        <Card className="my-3 border-danger bg-secondary text-white">
            <Card.Header
                    onClick={() => setOpen(!open)}
                    aria-controls="debug-collapse"
                aria-expanded={open}
            >
                <strong>Debug</strong>
            </Card.Header>
            <Collapse in={open}>
                <div id="debug-collapse">
                    <Card.Body variant="secondary">
                        <Button variant="danger" onClick={() => API.reset()}>
                            Reset
                        </Button>
                    </Card.Body>
                    <Card.Footer>
                        <pre style={{ fontSize: '0.7em' }}>
                            {JSON.stringify(props.state, null, 4)}
                        </pre>
                    </Card.Footer>
                </div>
            </Collapse>
        </Card>
    );
}
