import React from 'react';
import Title from './Title';
import Points from './Points';
import API from '../../Services/API';
import CustomPrize from './CustomPrize';

export default ({ adventure, trail, checkpoints }) => {

    return (
        <>

            <CustomPrize trail={trail} adventure={adventure} />
            
            <Title status={adventure.status} />

            <Points adventure={adventure} checkpoints={checkpoints} />

            <p>
                <a href={'https://checkpoint.game/leaderboard/' + trail.domain} className="btn btn-primary col-8 offset-2 mt-4" target="blank">
                    Check the leader board
                </a>
            </p>

            <p className="text-center mt-5">
                <button className="btn btn-secondary" onClick={() => API.reset()}>Close and start again</button>
            </p>
        </>
    );
}
