import React, {useEffect, useState} from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './Payment';
import Alert from 'react-bootstrap/Alert';
import Loading from '../../Components/Loading';
import API from '../../Services/API';
import Snippets from '../../Services/Snippets';

const date = new Date();
const dateStr = [date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()].join('-');

let stripePromise;

export default ({trail, onComplete, onError}) => {

    const [code, setCode] = useState(localStorage.getItem('code-' + dateStr));
    const [promiseReady, setPromiseReady] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState(null);

    useEffect(() => {
        stripePromise = loadStripe(
            trail.debug ? 'pk_test_kBBWEXJElbU1iz4XWVs2ldIi00LrzUmFs5' : 'pk_live_ZGvzabhTN7QVqW70ODDpjAdo00B8ldhRL1'
        );

        API.getPaymentIntent().then(intent => {
            setPaymentIntent(intent)
        });

        setPromiseReady(true);
    }, [trail.debug])


    const localComplete = (adventure) => {
        setCode(adventure.code);

        localStorage.setItem('code-' + dateStr, adventure.code);

        onComplete(adventure.code);
    }

    if (code) {
        return (
            <>
                <Alert variant="success" onClick={() => onComplete(code)}>
                    <h1>{code}</h1>
                </Alert>

                <p className="alert alert-warning">You'll need this code to switch to another device.</p>
            </>
        )

    } else if (promiseReady) {

        return (
            <Elements stripe={stripePromise}>
                <h2>Pay now</h2>
                <p>{Snippets.get('Pay_now')}</p>

                <Payment onComplete={(adventure) => localComplete(adventure)}
                    onError={(error) => onError(error)}
                    trail={trail}
                    paymentIntent={paymentIntent}
                />
            </Elements>
        );

    } else {
        return <Loading />
    }

}
