import React, {useEffect, useState} from 'react';

import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import Loading from '../../Components/Loading';
import Alert from 'react-bootstrap/Alert';
import API from '../../Services/API';
import CardPayment from './CardPayment';

export default (props) => {
    const { trail, onComplete, paymentIntent } = props;

    const enablePayNowButton = false;

    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);

    const [canMakePayment, setCanMakePayment] = useState(enablePayNowButton ? 'loading' : 'no');

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: trail.name,
                    amount: trail.cost,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            pr.on('paymentmethod', async (ev) => {
                console.log('ev', ev);
                
                API.paymentComplete({ paymentIntent, paymentMethod: ev.paymentMethod }, 'StripeDigitalWallet')
                    .then(adventure => onComplete(adventure))
                    .catch(() => alert('Sorry, there was an error processing your payment.'));;
            });

            // Check the availability of the Payment Request API.
            if (enablePayNowButton) {
                pr.canMakePayment().then(result => {
                    if (result) {
                        setCanMakePayment('yes');
                        setPaymentRequest(pr);
                    } else {
                        setCanMakePayment('no');
                        console.log('Cant make payments', result);
                    }
                });
            }
        }
    // eslint-disable-next-line
    }, [stripe]);

    console.log('canMakePayment', canMakePayment);
    
    if (paymentRequest && canMakePayment === 'yes') {
        return (
            <PaymentRequestButtonElement options={{ paymentRequest }} />
        );
        
    } else if (canMakePayment === 'no') {
        
        return <CardPayment paymentIntent={paymentIntent} onComplete={onComplete} />;
             
    } else if (canMakePayment === 'loading') {
        return <Loading />

    } else {
        return (
            <Alert variant="danger">
                Sorry, something went wrong.
            </Alert>
        );
    }
    
}
