import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Enrol from './Modules/Enrol';
import Header from './Components/UI/Header';
import Footer from './Components/UI/Footer';
import Debug from './Components/Debug';
import Checkpoints from './Modules/Checkpoints';
import API from './Services/API';
import Start from './Modules/Start';
import Loading from './Components/Loading';
import Timer from './Components/Timer';
import Points from './Components/Points';
import Summary from './Modules/Summary';
import TeamName from './Modules/TeamName';
import Finished from './Modules/Finished';
import Offline from './Modules/Offline';
import InitialQuestion from './Modules/InitialQuestion';
import FinalQuestion from './Modules/FinalQuestion';
import SetCustomPrize from './Modules/CustomPrize/Set';

export default () => {

    const [state, setState] = useState({});
    const [adventureStatus, setAdventureStatus] = useState(null);
    
    useEffect(() => {
        API.setSetUpdater(setState);
        API.nudge();
    }, []);

    useEffect(() => {
        if (state && state.adventure && state.adventure.status) {
            if (state.adventure.status !== adventureStatus) {
                window.scrollTo(0, 0);
                setAdventureStatus(state.adventure.status)
            }
        }
    }, [state, adventureStatus]);

    return (        
        <>
            <Header />

            <Container>

                {state.trail && state.adventure ? renderMainPage(state) : <Loading />}

                {(state.trail && state.trail.debug) && <Debug state={state} />}

                <Footer />

            </Container>
        </>
    );
}

const renderMainPage = (state) => {

    const onlineOnly = ['Started', 'Finished', 'InitialQuestion', 'FinalQuestion'];
    if (state.online === false && onlineOnly.indexOf(state.adventure.status) === -1) {
        return <Offline />

    } else {
        switch (state.adventure.status) {
            case 'Loading':
                return <Loading />
            
            case 'Pending':
                return <Enrol trail={state.trail} />

            case 'Booked':
                return (state.trail.customPrize && !state.adventure.customPrize) ?
                        <SetCustomPrize /> : <TeamName />
            
            case 'Named':
                return <Start debug={state.trail.debug} />
            
            case 'InitialQuestion':
                return <InitialQuestion />

            case 'Started':
                if (!state.checkpoints) {
                    return <Loading />
                }
                return (
                    <>
                        <p className="content lead">
                            Click each Checkpoint to select it, see the clue and enter your answer to score points!
                        </p>
                        <Timer startedAt={state.adventure.startedAt} time={state.trail.time} />
                        <Points adventure={state.adventure} checkpoints={state.checkpoints} />
                        <Checkpoints debug={state.trail.debug} checkpoints={state.checkpoints} adventure={state.adventure} />
                        
                    </>
                );

            case 'FinalQuestion':
                return <FinalQuestion />
            
            case 'Completed':
            case 'Failed':
            case 'Expired':
                if (!state.checkpoints) {
                    return <Loading />
                }

                return <Summary trail={state.trail} adventure={state.adventure} checkpoints={state.checkpoints}></Summary>
            
            case 'Finished':
                return <Finished pendingAttempts={state.pendingAttempts} />

            default:
                return <p>Uh oh :(</p>
        }
    }
}
