import React, {useState} from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import API from '../../Services/API';
import Loading from '../../Components/Loading';

export default (props) => {
    const cardFieldOptions = {
        style: {
            base: {
                borderColor: '#DDD',
                fontSize: '16px',
                lineHeight: '20px'
            }
        }
    };

    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    
    const handleSubmit = async (event) => {

        // Block native form submission.
        event.preventDefault();

        if (loading) {
            return
        }

        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement
        });

        if (error) {
            alert('Sorry there was an error, please try again.');
            console.log(error);
            setLoading(false);
        }
        
        API.paymentComplete({ paymentMethod, paymentIntent: props.paymentIntent }, 'StripeDigitalWallet')
            .then(adventure => props.onComplete(adventure))
            .catch(() => alert('Sorry, there was an error processing your payment.'));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <div className="form-control my-2">
                        <CardNumberElement options={cardFieldOptions} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-control my-2">
                        <CardExpiryElement options={cardFieldOptions} />
                    </div>
                </Col>
                <Col>
                    <div className="form-control my-2">
                        <CardCvcElement options={cardFieldOptions} />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button type="submit" disabled={!stripe || loading}>
                        {loading ? <>Pay Now <Loading size="sm" inline={true} variant="light" /></> : 'Pay Now'}
                    </Button>
                </Col>
                <Col>
                    {props.fakePaymentButton}
                </Col>
            </Row>
        </Form>
    );
}
