import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import API from '../../Services/API';
import Loading from '../../Components/Loading';
import Snippets from '../../Services/Snippets';

export default (props) => {

    const [loading, setLoading] = useState(false);

    const getCode = () => {
        setLoading(true);

        API.playNow('PlayNow').then(() => {
            // setLoading(false);
        });
    }

    return (
        <>
        <Row className="my-2">
            <Col>
                <h2 className="content landing">{Snippets.get('playNowTitle')}</h2>
                <p>{Snippets.get('playNow')}</p>

                
            </Col>
        </Row>


        <Row>
            <Col>
                <p>
                    <Button variant="primary" className="col-6 offset-3"
                        type="button"
                        onClick={() => getCode()}
                        // className="border-danger"
                        disabled={loading}
                    >
                        <span>{Snippets.get('playNowButton')}</span>
                        {loading && <> &nbsp; <Loading size="sm" variant="white" inline /></>}
                    
                    </Button>
                </p>
            </Col>
        </Row>
        </>
    );
}