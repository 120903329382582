import React from 'react';
import Button from 'react-bootstrap/Button';
import Loading from '../../Components/Loading';
import slide_one from "../../images/slide_one.png";
import slide_two from "../../images/slide_two.png";
import slide_three from "../../images/slide_three.png";
import slide_four from "../../images/slide_four.png";
import slide_one_medium from "../../images/slide_one_medium.png";
import slide_two_medium from "../../images/slide_two_medium.png";
import slide_three_medium from "../../images/slide_three_medium.png";
import slide_four_medium from "../../images/slide_four_medium.png";
import Snippets from '../../Services/Snippets';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';


export default ({ loading, start }) => {
    return (
        <>
            <div className="into-container">

                <AwesomeSlider
                        fillParent={true}
                        infinite={false}
                        bullets={true}
                        animation="cubeAnimation"
                        cssModule={AwesomeSliderStyles}
                        organicArrows={true}
                        buttons={true}
                >
                    <div className="intro-page">
                        <picture>
                            <source media="(min-width:650px)" srcSet={slide_one_medium} />
                            <img src={slide_one} alt="Flowers" className="slide-image" />
                        </picture>

                        <h2 className="caption">{Snippets.get('slide_one_caption')}</h2>
                        <h2 className="caption">Swipe to keep going</h2>
                    </div>

                    <div className="intro-page">
                        <picture>
                            <source media="(min-width:650px)" srcSet={slide_two_medium} />
                            <img src={slide_two} alt="Flowers" className="slide-image" />
                        </picture>

                        <h2 className="caption">{Snippets.get('slide_two_caption')}</h2>
                        <h2 className="caption">Swipe to keep going</h2>
                    </div>

                    <div className="intro-page">
                        <picture>
                            <source media="(min-width:650px)" srcSet={slide_three_medium} />
                            <img src={slide_three} alt="Flowers" className="slide-image" />
                        </picture>

                        <h2 className="caption">{Snippets.get('slide_three_caption')}</h2>
                        <h2 className="caption">Swipe to keep going</h2>
                    </div>

                    <div className="intro-page">
                        <picture>
                            <source media="(min-width:650px)" srcSet={slide_four_medium} />
                            <img src={slide_four} alt="Flowers" className="slide-image" />
                        </picture>
                        
                        <h2 className="caption">{Snippets.get('slide_four_caption')}</h2>

                        <Button variant="primary" className="col-10" type="button" onClick={() => start()} disabled={loading}>
                            {loading ? <>Start  <Loading inline={true} size="sm" variant="light" /></> : 'Start'}
                        </Button>
                    </div>

                </AwesomeSlider>
            </div>

            <div className="clearfix"></div>
        </>
    )   
}
