import React from 'react';
import '../../App.scss';

export default (props) => {

    const words = {
        'Completed': {
            title: 'Mission Complete',
            blurb: 'Congratulations, you have proven yourselves to be amongst the best - see where you came on our leaderboard below'
        },
        'Failed': {
            title: 'Great effort',
            blurb: 'Although you did not quite get them all right that was a great try.  See where you came on our leaderboard below'
        },
        'Expired': {
            title: 'Times up!',
            blurb: 'That was a great try at collecting the Checkpoints. Take a look where you came on the leaderboard'
        },
    }

    return (
        <>
            <h1 className="adventure-title">{words[props.status].title}</h1>

            <p className="content">{words[props.status].blurb}</p>
        </>
    );
}
