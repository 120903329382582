import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import API from '../../Services/API';
import '../../App.scss';

export default (props) => {

    // Skip the timer if it's all 9s
    const time = props.time.toString();
    const match = time.match(/^9+$/);
    if (match && match[0] === time) {
        return null;
    }

    const duration = Math.round(props.time/60); // in Minutes

    const start = Math.round(new Date(props.startedAt.replace(' ', 'T') + 'Z').getTime() / 1000);

    const [refreshed, setRefreshed] = useState(false);
    const [percent, setPercent] = useState(0);
    const [animated, setAnimated] = useState(true);
    const [striped, setStriped] = useState(false);
    const [label, setLabel] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Math.round(new Date().getTime() / 1000);
            
            let _elapsedSeconds = (now - start);
            let _elapsed = Math.floor(_elapsedSeconds / 60);
            // Ensure elapsed time doesn't get above the duration
            if (_elapsed > duration) {
                _elapsed = duration;
            }
            if (_elapsedSeconds > (duration * 60)) {
                _elapsedSeconds = duration * 60;
            }
            
            let _remaining = duration - _elapsed;
            const _percent = Math.round(100 / (duration * 60) * _elapsedSeconds);

            if (_remaining === 0) {
                setLabel('Time up!');
                setAnimated(false);
                setStriped(false);

                if (refreshed === false) {
                    setRefreshed(true);
                    API.checkExpired();
                }

            } else if (_remaining <= 30) {
                setLabel( `${_remaining} minutes remaining` );
                setAnimated(true);
                setStriped(true);
                
            } else {
                setLabel(`${_remaining} minutes remaining` );
                setAnimated(false);
                setStriped(false);
            }
        
            setPercent( _percent );

        }, 1000);

        return () => clearInterval(interval);
    }, [duration, start, refreshed]);


    return (
        <ProgressBar className="my-4 font-weight-bold progress_bar border border-dark">
            <ProgressBar className="progress_bar text-dark border-right border-dark"
                striped={striped}
                animated={animated}
                now={percent}
                label={percent > 50 ? label : ''}
                variant='warning'
            />
            <ProgressBar
                now={100 - percent}
                label={percent < 50 ? label : ''}
                className="text-dark"
                variant='light'
            />
            
        </ProgressBar>
    );
}
