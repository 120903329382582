import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default (props) => {
    
    const spinner = (
        <Spinner animation="border" role="status" size={props.size} variant={props.variant ?? 'primary'}>
            <span className="sr-only">Loading...</span>
        </Spinner>
    );

    if (props.inline) {
        return (
            <span>
                {spinner}
            </span>
        );

    } else {

        return (
            <div className="text-center my-3">
                {spinner}
            </div>
        );
    }
}
