import React, { useState } from 'react';
import Checkpoint from './Checkpoint';
import '../../App.scss';

export default (props) => {
    const [openCheckpoint, setOpenCheckpoint] = useState(null);

    return props.checkpoints.reduce((rows, point) => {
        rows.push((
            <Checkpoint
                debug={props.debug}
                key={point.id}
                checkpoint={point}
                progress={props.adventure.checkpoints[point.id]}
                isOpen={openCheckpoint === point.id}
                onOpen={(id) => setOpenCheckpoint(id)}
                onClose={(id) => setOpenCheckpoint(null)}
            />
        ));

        return rows;
    }, []);
}
