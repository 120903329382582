import themeSnippets from '../snippets';

export default new class Snippets {
    defaults = {
        hello: 'Default hello',
        words: 'Default words',

        playNow: 'To get started hit play now!',
        initialQuestion: 'Please answer the initial question',
        finalQuestion: 'Please answer the final question',

        customPrizePrompt: 'Your crotch goblin will be given this IF they complete the challenge and earn the prize',
        customPrizeCompleted: 'The treasure is hidden in...',
        customPrizeFailed: 'Maybe try a bit harder next time?',
        customPrizeExpired: 'Chop chop, mate.',
    };

    get(key) {
        return themeSnippets[key] ?? this.defaults[key] ?? '{{'+key+'}}'
    }
}()
