import React from 'react';

export default () => {

    return (
        <>
            <h1>Offline</h1>
            
            <p>You can be offline during the game but you must be online to start or finish it.</p>

            <p>Please go back online to complete this step.</p>
            
            <p>Refresh the page if you're online and still seeing this message.</p>
        </>
    )
}
