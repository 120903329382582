import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import checkpoint_logo from '../../images/checkpointlogo.png';
// import logo from '../../images/logo.png';

export default () => {

    return (
    <>
        <Navbar >
            <Container>
                <Row className="justify-content-center">
                <img
                    alt=""
                    src={checkpoint_logo}
                    className="navbarlogo"
                />
                </Row>
            </Container>

        </Navbar>


    </>

    );
}