import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import API from '../../Services/API';
import Loading from '../../Components/Loading';
import Form from 'react-bootstrap/Form';
import teamname from '../../images/teamname.png';
import Snippets from '../../Services/Snippets';

export default () => {

    const [loading, setLoading] = useState(false);
    const [teamName, setTeamName] = useState('');

    const submit = (ev) => {
        ev.preventDefault();

        setLoading(true);
        API.setTeamName(teamName).catch((err) => {
            setLoading(false);
            alert(err)
        });
    }

    return (
        <>

            <Row>
                <Col>

                <img className="puzzle_type_logo" src={teamname} alt="team name logo"></img>

                <h2 className="adventure-title">Please enter your team name</h2>

                <p className="content">{Snippets.get('Teamname_text')}</p>
                    
                    <Form onSubmit={(ev) => submit(ev)}>
                        <Form.Group>
                            <Form.Control type="text" placeholder="Type here" value={teamName} onChange={e => setTeamName(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" className="col-6 offset-3" type="button" onClick={(ev) => submit(ev)} disabled={loading}>
                            {loading ? <>Submit <Loading inline={true} size="sm" variant="light" /></> : 'Submit'}
                        </Button>
                    </Form>
                    
                </Col>
            </Row>
        </>
    );
}

