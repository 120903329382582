import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import goldMedal from '../../images/gold_medal.png';
import silverMedal from '../../images/silver_medal.png';
import bronzeMedal from '../../images/bronze_medal.png';


export default (props) => {

    if (!props.adventure.checkpoints) {
        return null;
    }

    const available = props.checkpoints.reduce((total, checkpoint) => {
        return total + checkpoint.pointsCorrect;
    }, 0);

    const points = props.adventure.points;

    // Work out the duration
    const start = Math.round(new Date(props.adventure.startedAt.replace(' ', 'T') + 'Z').getTime() / 1000);
    const finish = Math.round(new Date(props.adventure.finishedAt.replace(' ', 'T') + 'Z').getTime() / 1000);
    const time = Math.floor((finish - start) / 60) + ':' + Math.floor((finish - start) % 60);

    if (points === available) {
        return render('Gold Medal', goldMedal, points, time)

    } else if (points < available && points >= (available / 2)) {
        
        return render('Silver Medal', silverMedal, points, time)
        
    } else {
        return render('Bronze Medal', bronzeMedal, points, time)
    }

}

const render = (title, medal, points, time) => {
    return (
        <>
            <Col xs={12}>
                <Card className="my-3 shadow bg-white rounded">
                    <Card.Body>
                        <h1 className="adventure-title">{title}</h1>
                        <img className="medal" alt={title} src={medal}></img>
                    </Card.Body>
                </Card>
            </Col>

            <Row>
                <Col xs={6}>
                    <Card className="puzzletype shadow p-2 mb-5 bg-white rounded adventure-title responsive-subtitle"><h2>Points</h2>
                        <h2 className="responsive-subtitle">{points}</h2>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card className="puzzletype shadow p-2 mb-5 bg-white rounded adventure-title responsive-subtitle"><h2>Time</h2>
                        <h2 className="responsive-subtitle">{time}</h2>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
