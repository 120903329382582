export default {
    Trail_name: 'Operation Checkpoint',
    playNowTitle:'Get Started',
    playNowButton:'Play Now',

    Headline_info: 'If you\'d like to take part in this trail please purchase a code from reception.  We also recommend wearing outdoor footwear as some sections can be muddy.',
    Get_started:'Enter your 6 digit game code in the box below, only one of your team needs to do this.  If you don\'t have one yet, head to reception to purchase your code.',
    Pay_now:'Enter your card details below to purchase a code and play this Adventure Trail.  Your card will be charged a one-off fee of £10.  For full T\'s&C\'s, click the link in the footer.',

    Teamname_text:'Your first task?  Agree on a suitable team name so you can be proud when you\'re top of the leaderboard',

    Explainer_page_header:'Your Mission!',

    slide_one_caption:'At the FAF (Federation of Advanced Forestry) we recruit only the best individuals so we\'ve set a series of challenges for you; complete as many as you can within the time to score points! They fall into 3 categories',
    slide_two_caption:'HUNT checkpoints are sneaky and well hidden, so keep your eyes peeled.  SOLVE checkpoints will be easier to find but will require some clever thinking and PLAY checkpoints need you to interact with them to work out the code word.',
    slide_three_caption:'The rough location of each checkpoint is on your map so plan your route carefully to make sure you visit them all!  You loose points for any incorrect answers and get three attempts per question, so don\'t guess!',
    slide_four_caption:'When you\'re ready, hit the statt button below and your time will start!, GOOD LUCK!',

}