import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import API from '../../Services/API';
// import Simple from './Simple';
import Animated from './Animated';

export default ({debug}) => {

    const [loading, setLoading] = useState(false);

    const start = () => {
        setLoading(true);
        API.start().catch((err) => {
            setLoading(false);
            alert(err)
        });
    }

    return (
        <>
            {/* <Col xs={12}> */}
                <Row className="content">
                    
                    {/* {debug || <Simple loading={loading} start={() => start()} />} */}
                    
                    <Animated loading={loading} start={() => start()} />


            </Row>
        {/* </Col> */}
        </>
    );
}
