import React from 'react';
import Snippets from '../../Services/Snippets';

export default ({ trail, adventure }) => {

    // If there is no custom prize... nothing
    if (!trail.customPrize || !adventure.customPrize) {
        return null;
    }

    const containerStyles = 'my-3 py-3 shadow bg-white rounded text-center';

    switch (adventure.status) {
        case 'Completed':
            return (
                <div className={containerStyles + ' text-dark animate__animated animate__pulse animate__infinite'}>
                    <p>{Snippets.get('customPrizeCompleted')}</p>

                    <h2>{adventure.customPrize}</h2>
                </div>
            )
        
        case 'Failed':
            return (
                <div className={containerStyles}>
                    <p>{Snippets.get('customPrizeFailed')}</p>
                </div>
            )
        
        case 'Expired':
            return (
                <div className={containerStyles}>
                    <p>{Snippets.get('customPrizeExpired')}</p>
                </div>
            )
        
        default:
            // This should never happen
            return null;
    }
}
