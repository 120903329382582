import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import AnswerForm from './AnswerForm';
import '../../App.scss';
import 'animate.css';

const ANIMATIONS_COMPLETED = ['animate__bounce', 'animate__tada', 'animate__heartBeat', 'animate__rubberBand', 'animate__bounceIn'];
const ANIMATIONS_FAILED = ['animate__hinge', 'animate__backOutLeft', 'animate__rotateOutUpRight'];
const ANIMATIONS_ATTEMPTED = ['animate__headShake', 'animate__shakeX', 'animate__swing', 'animate__wobble'];

export default (props) => {
    // const { debug, isOpen, onClose, onOpen, progress } = props; this one includes the debug 
    const { isOpen, onClose, onOpen, progress } = props;
    const { id, title, question, type } = props.checkpoint;

    const [animationClasses, setAnimationClasses] = useState('');
    const [skipFirst, setSkipFirst] = useState(true);
    const [lastRandom, setLastRandom] = useState(null);

    const status = progress ? progress.status : null;
    const maxAttempts = props.checkpoint.maxAttempts;
    const usedAttempts = progress ? progress.attempts : 0;
    const remainingAttempts = maxAttempts - usedAttempts;

    // Pick a random element from an array - but never the same one twice
    const rand = (arr) => {
        const r = Math.floor(Math.random() * arr.length);

        // If we've seen this random number before, try again
        if (r === lastRandom) {
            return rand(arr);
        }

        setLastRandom(r);
        return arr[r];
    };

    useEffect(() => {
        // if (!debug) {
        //     return ''
        // };

        if (skipFirst) {
            setSkipFirst(false);
            return;
        }

        if (status === 'Completed') {
            setAnimationClasses(' animate__animated animate__repeat-1 '+rand(ANIMATIONS_COMPLETED));

        } else if (status === 'Failed') {
            setAnimationClasses(' animate__animated animate__repeat-1 '+rand(ANIMATIONS_FAILED));

        } else if (usedAttempts > 0) {
            setAnimationClasses(' animate__animated animate__repeat-1 '+rand(ANIMATIONS_ATTEMPTED));

        } else {
            return;
        }

        setTimeout(() => setAnimationClasses(' '), 3000);
        // eslint-disable-next-line
    }, [status, usedAttempts])
    
    if (status === 'Completed') {
        return (
            <Card className={'my-2 greyout checkpoint-' + type+' '+animationClasses} text="success">
                <Card.Header>
                    <Row>
                        <Col xs={2}><span role="img" aria-label="tick">✅</span></Col>
                        <Col xs={10}>{title}</Col>
                    </Row>
                </Card.Header>
            </Card>
        );
    }

    if (status === 'Failed') {
        return (
            <Card className={'my-2 greyout checkpoint-' + type+' '+animationClasses} text="danger">
                <Card.Header>
                    <Row>
                        <Col xs={2}><span role="img" aria-label="cross">❌</span></Col>
                        <Col xs={10}>{title}</Col>
                    </Row>
                </Card.Header>
            </Card>
        );
    }
    
    return (
        <Card className={'my-3 shadow bg-white rounded checkpoint-' + type +' '+animationClasses} > 
            <Card.Header onClick={() => isOpen ? onClose(id) : onOpen(id)} 
                aria-controls={'checkpoint-collapse-' + id}
                aria-expanded={isOpen}
                className="checkpoint"
                >
                <Row className="align-items-center pirates">
                    <Col xs={2}><i /></Col>
                    <Col xs={8}>{title}</Col>
                    <Col xs={2} className="centered-text responsive-subtitle">{props.checkpoint.pointsCorrect}<br></br>pts</Col>
                </Row>
            </Card.Header>
            
            <Collapse in={isOpen}>
                <div id={'checkpoint-collapse-' + id}>

                    <Card.Body className="pirates">

                        <p>{remainingAttempts} of {maxAttempts} attempts left</p>

                        <p className="question pirates">
                            Question<br />
                            <span className="question_text">{question}</span>
                        </p>

                    </Card.Body>

                    <Card.Footer>
                        <AnswerForm checkpointId={props.checkpoint.id} />
                    </Card.Footer>
                </div>
            </Collapse>
        </Card>
    );
}
